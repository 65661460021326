/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path opacity={`0.9`} fillRule={`evenodd`} clipRule={`evenodd`} d={`M14 2L2 2L2 14H14L8 8L14 2Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path opacity=\"0.9\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14 2L2 2L2 14H14L8 8L14 2Z\" />\n</svg>\n";
export default src;
