/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`17`} height={`17`} viewBox={`0 0 17 17`}{...props}><path fillRule={`evenodd`} clipRule={`evenodd`} d={`M2.11552 1.63574C1.90229 1.63557 1.69112 1.67744 1.49409 1.75896C1.29706 1.84047 1.11804 1.96004 0.96726 2.11082C0.816485 2.26159 0.696918 2.44062 0.6154 2.63765C0.533882 2.83468 0.492013 3.04585 0.492188 3.25908L0.492188 13.3677C0.492013 13.581 0.533882 13.7921 0.6154 13.9892C0.696918 14.1862 0.816485 14.3652 0.96726 14.516C1.11804 14.6668 1.29706 14.7863 1.49409 14.8679C1.69112 14.9494 1.90229 14.9913 2.11552 14.9911H14.4702C14.6834 14.9913 14.8946 14.9494 15.0916 14.8679C15.2886 14.7863 15.4677 14.6668 15.6184 14.516C15.7692 14.3652 15.8888 14.1862 15.9703 13.9892C16.0518 13.7921 16.0937 13.581 16.0935 13.3677V5.50574C16.0937 5.29252 16.0518 5.08135 15.9703 4.88431C15.8888 4.68728 15.7692 4.50826 15.6184 4.35748C15.4677 4.20671 15.2886 4.08714 15.0916 4.00562C14.8946 3.9241 14.6834 3.88224 14.4702 3.88241L7.41952 3.88241L6.22285 2.28708C6.07205 2.08457 5.87592 1.92019 5.65018 1.80708C5.42443 1.69398 5.17535 1.6353 4.92285 1.63574L2.11552 1.63574ZM8.29552 9.07841C8.70502 9.07841 9.09774 8.91574 9.38729 8.62618C9.67685 8.33663 9.83952 7.94391 9.83952 7.53441C9.83952 7.12492 9.67685 6.73219 9.38729 6.44264C9.09774 6.15308 8.70502 5.99041 8.29552 5.99041C7.88603 5.99041 7.4933 6.15308 7.20375 6.44264C6.91419 6.73219 6.75152 7.12492 6.75152 7.53441C6.75152 7.94391 6.91419 8.33663 7.20375 8.62618C7.4933 8.91574 7.88603 9.07841 8.29552 9.07841ZM8.29219 9.98908C9.57819 9.98908 10.7302 10.7024 11.3662 11.7477C11.5995 12.1304 11.2815 12.5671 10.8342 12.5671H5.75085C5.30285 12.5671 4.98552 12.1304 5.21819 11.7471C5.85419 10.7024 7.00619 9.98908 8.29219 9.98908Z`}/></svg>
);

let src = "<svg width=\"17\" height=\"17\" viewBox=\"0 0 17 17\" xmlns=\"http://www.w3.org/2000/svg\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.11552 1.63574C1.90229 1.63557 1.69112 1.67744 1.49409 1.75896C1.29706 1.84047 1.11804 1.96004 0.96726 2.11082C0.816485 2.26159 0.696918 2.44062 0.6154 2.63765C0.533882 2.83468 0.492013 3.04585 0.492188 3.25908L0.492188 13.3677C0.492013 13.581 0.533882 13.7921 0.6154 13.9892C0.696918 14.1862 0.816485 14.3652 0.96726 14.516C1.11804 14.6668 1.29706 14.7863 1.49409 14.8679C1.69112 14.9494 1.90229 14.9913 2.11552 14.9911H14.4702C14.6834 14.9913 14.8946 14.9494 15.0916 14.8679C15.2886 14.7863 15.4677 14.6668 15.6184 14.516C15.7692 14.3652 15.8888 14.1862 15.9703 13.9892C16.0518 13.7921 16.0937 13.581 16.0935 13.3677V5.50574C16.0937 5.29252 16.0518 5.08135 15.9703 4.88431C15.8888 4.68728 15.7692 4.50826 15.6184 4.35748C15.4677 4.20671 15.2886 4.08714 15.0916 4.00562C14.8946 3.9241 14.6834 3.88224 14.4702 3.88241L7.41952 3.88241L6.22285 2.28708C6.07205 2.08457 5.87592 1.92019 5.65018 1.80708C5.42443 1.69398 5.17535 1.6353 4.92285 1.63574L2.11552 1.63574ZM8.29552 9.07841C8.70502 9.07841 9.09774 8.91574 9.38729 8.62618C9.67685 8.33663 9.83952 7.94391 9.83952 7.53441C9.83952 7.12492 9.67685 6.73219 9.38729 6.44264C9.09774 6.15308 8.70502 5.99041 8.29552 5.99041C7.88603 5.99041 7.4933 6.15308 7.20375 6.44264C6.91419 6.73219 6.75152 7.12492 6.75152 7.53441C6.75152 7.94391 6.91419 8.33663 7.20375 8.62618C7.4933 8.91574 7.88603 9.07841 8.29552 9.07841ZM8.29219 9.98908C9.57819 9.98908 10.7302 10.7024 11.3662 11.7477C11.5995 12.1304 11.2815 12.5671 10.8342 12.5671H5.75085C5.30285 12.5671 4.98552 12.1304 5.21819 11.7471C5.85419 10.7024 7.00619 9.98908 8.29219 9.98908Z\" />\n</svg>\n";
export default src;
